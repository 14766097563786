var Dropdown;

jQuery('.dropdown').find('.dropdown-toggle').append('<div class="arrow"></div>');

Dropdown = {
  init: function () {
    jQuery('.dropdown').mouseenter(function () {
      var $dropdownMenu, $self;

      $self = jQuery(this);
      $dropdownMenu = $self.find('.dropdown-menu');

      if (jQuery(window).innerWidth() > 991.98) {
        $dropdownMenu.addClass('show');
        $self.addClass('open');
      }
    });

    jQuery('.dropdown').mouseleave(function () {
      var $dropdownMenu, $self;

      $self = jQuery(this);
      $dropdownMenu = $self.find('.dropdown-menu');

      if (jQuery(window).innerWidth() > 991.98) {
        $dropdownMenu.removeClass('show').attr('style', '');
        $self.removeClass('open');
      }
    });

    jQuery('.dropdown').click(function (e) {

      if (jQuery(window).innerWidth() < 991.98) {
        jQuery(this).parent().siblings('.navbar-nav').find('.dropdown').removeClass('open');
      }

      if (jQuery(window).innerWidth() > 991.98) {
        e.stopPropagation();
      }
    });

    // Mobile intended
    jQuery('.menu-item-has-children.dropdown').click(function (e) {

    });

    jQuery('.menu-item-has-children.dropdown .arrow').click(function (e) {
      var $dropdownMenu, $dropdown;

      $dropdown = jQuery(this).parents().eq(1);
      $dropdownMenu = $dropdown.find('.dropdown-menu');

      if (jQuery(window).innerWidth() < 991.98) {
        e.preventDefault();

        jQuery(this).parents().eq(1).siblings('.menu-item').removeClass('open');

        $dropdown.toggleClass('open');
      }
    });

    // Events
    jQuery('.dropdown').on('show.bs.dropdown', function () {

    });

    jQuery('.dropdown').on('shown.bs.dropdown', function () {
      if (jQuery(window).innerWidth() > 991.98) {
        // jQuery('body').addClass('dropdown-overlay');
      }
    });

    jQuery('.dropdown').on('hidden.bs.dropdown', function () {
      if (jQuery(window).innerWidth() > 991.98) {
        // jQuery('body').removeClass('dropdown-overlay');
      }
    });
  },

  reset: function () {
    jQuery('.navbar-nav').find('.dropdown-menu').removeClass('show');
    jQuery('.navbar-nav').find('.dropdown').removeClass('open');
  }
}

Dropdown.init();

jQuery(window).resize(function () {
  Dropdown.reset();
  Dropdown.init();
});
