var ShowMore = {};

( function ( $ ) {
	var triggers = $( '.js-show-more-trigger' );

	ShowMore.init = function () {
		$.each( triggers, function () {
			var element = $( this );

			element.click( function ( event ) {
				event.preventDefault();

				var target = $( element.data( 'target' ) );
				target.removeClass( 'hide' );

				element.addClass( 'hide' );
			} );
		} );
	};

	if ( triggers.length > 0 ) {
		$( ShowMore.init );
	}

} )( jQuery );
