var Toggle = {};

( function ( $ ) {
	var selectTriggers = $( '.js-toggle-select-trigger' );

	Toggle.init = function () {
		$.each( selectTriggers, function () {
			var select = $( this );
			var count = parseInt( select.data( 'count' ) );

			select.change( function () {
				$( '.js-toggle-select' ).addClass( 'hide' );

				var itemsToShow = $( '.js-toggle-select-' + select.val() );

				itemsToShow.each( function ( index, item ) {
					var item = $( item );

					if ( ( index + 1 ) <= count ) {
						item.removeClass( 'hide' );
					}
				} );

				$( '.readmore-button .js-toggle-select-' + select.val() ).removeClass( 'hide' );

				if ( itemsToShow.length <= count ) {
					$( '.readmore-button .js-show-more-trigger.js-toggle-select-' + select.val() ).remove();
				}
			} );

			select.trigger( 'change' );
		} );
	};

	if ( selectTriggers.length > 0 ) {
		$( Toggle.init );
	}

} )( jQuery );
