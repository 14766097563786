var BackgroundImageSwitcher = {};

( function ( $ ) {

	var targets = $( '.js-background-image' );

	var updateBackground = function () {
		$.each( targets, function ( index, value ) {

			var _this = $( value );
			var background;

			if ( Viewport.documentWidth().width < 991) {
				background = _this.data( 'mobileBackground' );
			} else {
				background = _this.data( 'desktopBackground' );
			}

			_this.css( { 'background-image': 'url(' + background + ')' } );
		} );
	};

	BackgroundImageSwitcher.init = function () {
		updateBackground();

		$( window ).on( 'resize', function () {
			updateBackground();
		} );
	};

	if ( targets.length > 0 ) {
		$( BackgroundImageSwitcher.init );
	}

} )( jQuery );
