var Video = {}, iframeCount = 0, monitor;

Video.isMobile = function () {
    return Viewport.documentWidth().width < 991.98;
};

Video.iframeProcess = function () {
    for (var i = 0; i < Video.iframes.length; i++) {

        if (!Video.iframes[i].getAttribute('data-autoplay') || Video.isMobile()) { // Video not set to autoplay or video is set to autoplay but viewed on mobile device
            var playVideo = Video.iframes[i].parentNode.querySelector('.js-playvideo');

            if (!playVideo) {
                return;
            }

            playVideo.addEventListener('click', function (event) {
                event.preventDefault();

                var iframe = this.parentNode.querySelector('.js-video-iframe'), figure = this.parentNode.querySelector('figure');

                Array.prototype.forEach.call(this.parentNode.querySelectorAll('.js-hide-on-play'), function (element) {
                    element.parentNode.removeChild(element);
                });

                figure.removeChild(figure.firstElementChild);

                iframe.style.opacity = 1;
                iframe.src += '&autoplay=1';
                figure.style.backgroundColor = 'black';
            });

            // Iframe overlaps play button in mobile
            if (Video.isMobile()) {
                Video.iframes[i].style.zIndex = 1021;
            }

            // Make sure video is not muted and autoplay is disabled
            Video.iframes[i].src = Video.iframes[i].src.replace('&muted=1', '').replace('&mute=1', '').replace('&loop=1', '').replace('&autoplay=1', '');
        } else { // Video is set to autoplay
            var figure = Video.iframes[i].parentNode.querySelector('figure');

            Array.prototype.forEach.call(Video.iframes[i].parentNode.querySelectorAll('.js-hide-on-play'), function (element) {
                element.parentNode.removeChild(element);
            });

            if (!Video.isMobile()) {
                figure.removeChild(figure.firstElementChild);
            }

            Video.iframes[i].style.opacity = 1;
            figure.style.backgroundColor = 'black';
        }
    }
};

Video.init = function () {
    Video.iframes = document.querySelectorAll('iframe.js-video-iframe');
    Video.tags = document.querySelectorAll('video.js-video-tag');

    if (Video.iframes.length) {
        Video.iframeProcess();
    }

    if (Video.tags.length) {
        for (var i = 0; i < Video.tags.length; i++) {
            if (Video.tags[i].getAttribute('data-autoplay')) {
                // Put video to the front
                Video.tags[i].style.zIndex = 10;
            } else {
                // Play video upon clicking the play button
                var playVideo = Video.tags[i].parentNode.querySelector('.js-playvideo');

                if (!playVideo) {
                    continue;
                }

                playVideo.addEventListener('click', function (event) {
                    event.preventDefault();
                    var targetVideo = this.parentNode.querySelector('video');

                    Array.prototype.forEach.call(this.parentNode.querySelectorAll('.js-hide-on-play'), function (element) {
                        element.parentNode.removeChild(element);
                    });

                    targetVideo.play();
                    targetVideo.style.zIndex = 1;
                });
            }
        }
    }

    if (Video.isMobile() && Video.iframes.length !== 0) {
        monitor = setInterval(function () {
            var elem = document.activeElement;

            if (elem && elem.tagName === 'IFRAME' && elem.style.opacity !== '1') {
                var figure = elem.parentNode.querySelector('figure');

                Array.prototype.forEach.call(elem.parentNode.querySelectorAll('.js-hide-on-play'), function (element) {
                    element.parentNode.removeChild(element);
                });

                // Adds class to section
                if (this.innerWidth < 991.98) {
                    elem.parentNode.classList.add('is-playing');
                    elem.parentNode.classList.add('embed-responsive', 'embed-responsive-16by9')
                    elem.parentNode.querySelector('.hero-iframe-video').classList.add('embed-responsive-item');
                }

                // figure.removeChild(figure.firstElementChild);

                elem.style.opacity = 1;
                // figure.style.backgroundColor = 'black';

                iframeCount++;

                if (iframeCount === Video.iframes.length) {
                    clearInterval(monitor);
                }
            }

            console.log('iframes played ' + iframeCount);
        }, 1000);
    }
};

Video.init();
