( function ( $ ) {
	// $( document ).ready( function () {
	// 	$( '.section-text' ).each( function ( i ) {
	// 		$this = $( this );

	// 		$this.addClass( 'section-' + i );
	// 		if ( $this.find( '.button-center' ).length >= 2 ) {
	// 			$this.addClass( 'buttons--centered' );
	// 		}
	// 		if ( $this.find( '.button-center' ).length === 1 ) {
	// 			$this.find( '.button-center' ).addClass( 'is-wide' );
	// 		}
	// 	} );

	// 	$( 'main' ).children().last().addClass( 'last' );
	// } );
} )( jQuery );
