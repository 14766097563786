var vfbModifiers = {};

(function ($) {
    var labelCheckbox = $('.vfb-checkbox label');
    var labelRadio = $('.vfb-radio label');


    vfbModifiers.init = function () {
        labelCheckbox.append( '<span class="checkmark"></span>' );
        labelRadio.append( '<span class="checkmark"></span>' );
    };

    if (labelCheckbox.length > 0 || labelRadio.length > 0) {
        $(vfbModifiers.init);
    }

})(jQuery);