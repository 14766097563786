( function( $ ) {
	$(document).ready(function(){
        var numImgs = $('.full-width-section-slider figure').length;
        if (numImgs > 1) {
            $('.full-width-section-slider').bxSlider({
                preloadImages: 'all',
                // pager: false
            });
        }
    });
} )( jQuery );
