var Navigation;

Navigation = {
    init: function () {
        jQuery('.navbar-toggle-custom').on('click', function () {
            if (jQuery('.navbar').hasClass('nav-open')) {
                jQuery('.navbar').removeClass('nav-open');
            } else {
                jQuery('.navbar').addClass('nav-open');
            }
        });
    },
    reset: function () {
        jQuery('.menu-item.dropdown').removeClass('open');
        jQuery('.navbar').removeClass('nav-open');
    }
}

jQuery(function () {
    Navigation.init();

    jQuery(window).resize(function () {
        Navigation.reset();
    });
});
