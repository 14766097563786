var MultiImage = {};

( function ( $ ) {
	var loadImage = function ( element ) {
		var urls = element.data( 'urls' );

		if ( urls ) {
			urls = urls.split( '|' );

			var firstItem = urls.shift();

			if ( firstItem ) {
				element.prop( 'src', firstItem );
				element.data( 'urls', urls.join( '|' ) );
			}
		} else {
			var parent = element.parent();
			var parentAltClass = element.data( 'parentAltClass' );

			if ( parentAltClass ) {
				parent.addClass( parentAltClass );
			}

			var altText = element.prop( 'alt' );

			if ( altText ) {
				parent.html( '<div>' + altText + '</div>' );
			}

			element.off( 'error' );
		}
	};

	MultiImage.init = function ( targets ) {

		$.each( targets, function () {
			var element = $( this );

			if ( element.prop( 'src' ) ){
				return;
			}

			element.on( 'error', function () {

				var element = $( this );
				loadImage( element );
			} );

			loadImage( element );
		} );

	};

	var targets = $( '.js-multi-image' );

	if ( targets.length ) {
		$( MultiImage.init( targets ) );
	}
} )( jQuery );